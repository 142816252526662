/* 일요일 날짜: 빨간색 */
.fc-day-sun a {
    color: red;
}
  
/* 토요일 날짜: 파란색 */
.fc-day-sat a {
    color: blue;
}

@media (pointer:coarse) {
    .fc .fc-toolbar-title {
        font-size: 20px;
    }
    .fc .fc-button {
        padding: 0.25em 0.35em

    }

}
